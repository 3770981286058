<template>
    <Layout title="文章详情">    
        <template #extend>
            <el-button @click="handleReturn">返回上一页</el-button>
        </template>    

        <el-card class="custom-card__outer">
            <el-form :model="form" :rules="rules" ref="formRef">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="标题" prop="title">
                            <el-input v-model="form.title" placeholder="请填写手机号码" />
                        </el-form-item>
                        <el-form-item label="文章分类" prop="articleCatValue">
                            <el-select v-model="form.articleCatValue" placeholder="请选择文章分类">
                                <el-option label="请选择" :value="0" />
                                <el-option 
                                    v-for="item in parentCatList"
                                    :key="item.id"
                                    :label="item.catName" 
                                    :value="item.id" 
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所需积分" prop="needPoints">
                            <el-input type="number" v-model="form.needPoints" placeholder="请填写兑换所需要的积分数量" />
                        </el-form-item>
                        <el-form-item label="简介" prop="summary">
                            <el-input type="textarea" rows="4" v-model="form.summary" placeholder="请填写姓名" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="文章封面">
                            <UploadImage @handleSuccess="handleUploadSuccess" :imgSrc="form.preview" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="20">
                        <el-form-item label="附件">
                            <el-upload
                                class="uploader"
                                action
                                :file-list="form.attachment"
                                :before-upload="onBeforeFileUpload"
                                :http-request="onFileUpload"
                            >
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传pdf/doc文件，且不超过10M</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="文章内容" prop="content">
                            <div style="position:relative;border:1px solid #ddd;">
                                <Toolbar
                                    style="border-bottom:1px solid #ddd"
                                    :editor="editor"
                                    :defaultConfig="toolbarConfig"
                                    :mode="mode"
                                />
                                <Editor
                                    ref="wEditor"
                                    style="height:500px;overflow-y:hidden;"
                                    v-model="form.content"
                                    :defaultConfig="editorConfig"
                                    :mode="mode"
                                    @onCreated="onCreated"
                                />
                            </div>
                        </el-form-item> 
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <FixedActionBar>
            <el-button type="default" @click="handleCancel('formRef')">取消</el-button>
            <el-button type="primary" :loading="loading" :disabled="disabled" @click="handleConfirm('formRef')">确定</el-button>
        </FixedActionBar>
    </Layout>
</template>

<script>
import { DomEditor } from '@wangeditor/editor';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import '@wangeditor/editor/dist/css/style.css';

import {
    getOneArticleDetailApi,
    createArticleApi,
    editArticleApi,
    getArticleCatOptionsApi,
    api_uploadFile
} from '@/api/article';

export default {
    components: {
        Toolbar,
        Editor
    },

    data() {
        return {
            articleId: null,
            parentCatList: [],

            form: {
                title: '',
                cover: '',
                preview: '',
                articleCatValue: 0,
                needPoints: 0,
                summary: '',
                content: ''
            },

            rules: {
                title: [
                    { required: true, message: '请填写文章名称', trigger: 'blur' }
                ],
                articleCatValue: [
                    { required: true, message: '请选择文章分类', trigger: 'change' }
                ],
                summary: [
                    { required: true, message: '请填写文章简介', trigger: 'blur' }
                ]
            },

            editor: null,
            toolbarConfig: { 
                excludeKeys: ['insertLink', 'group-image', 'group-video', 'emotion', 'todo']
            },
            mode: 'default', // or 'simple'

            editorConfig: {
                placeholder: '请输入内容...',                
                MENU_CONF: {
                    uploadImage: {
                        // server: `${import.meta.env.VITE_APP_BASE_API}${uploadImgUrl}`,
                        // fieldName: 'file',
                        customUpload(file, insertFn) {
                            // Content-Type: multipart/form-data;
                            let formData = new FormData();
                            formData.append("file", file);

                            const loadingHandler = this.$loading({
                                text: '图片上传中...'
                            });
                            return new Promise((resolve, reject) => {
                                uploadImg(formData).then(res => {
                                    // {
                                    //     fileName: "common/image/dbtkkx1668137368096.png"
                                    //     filePath: "common/authImage/dbtkkx1668137368096.png"
                                    //     imageUrl: "https://panda-env-test.s3.ap-northeast-1.amazonaws.com/"
                                    // }
                                    loadingHandler.close();
                                    this.$message({
                                        type: 'success',
                                        message: `${file.name}上传成功`
                                    });
                                    const { fileName, imageUrl } = res.data;
                                    // Simulate async insert image
                                    insertFn(`${imageUrl}${fileName}`, file.name);
                                    resolve('ok');
                                }).catch(e => {
                                    loadingHandler.close();
                                    this.$message({
                                        type: 'success',
                                        message: `${file.name}上传失败`
                                    });
                                    reject(e);
                                });

                            });
                        },
                    }
                }
            },

            loading: false,
            disabled: false
        };
    },

    created() {
        new Promise(resolve => {
            const { query } = this.$route;
            if (query.id) {
                this.articleId = Number(query.id);
                this.getData(); 
            }
            resolve();
        }).then(() => {            
            this.getArticleTypeList();
        });
    },

    beforeDestroy() {
        const editor = this.editor;
        if (editor == null) return;
        editor.destroy(); // 组件销毁时，及时销毁编辑器
    },

    methods: {
        handleReturn() {
            this.$router.go(-1);
        },

        getData() {
            getOneArticleDetailApi({ articleId: this.articleId }).then(res => {
                if (res.data) {
                    const { title, articleCatId, needPoints, summary, content, cover, preview, attachment } = res.data;
                    this.form = {
                        title,
                        cover,
                        preview,
                        articleCatValue: articleCatId,
                        needPoints,
                        summary,
                        content,
                        attachment
                    };
                }
            });
        },

        onCreated(editor) {
            this.editor = Object.seal(editor);
        },

        // 获取文章类型列表
        getArticleTypeList() {
            getArticleCatOptionsApi().then(res => {
                if (res.data && res.data.length) {
                    this.parentCatList = res.data;
                }
            });
        },

        // 封面上传
        handleUploadSuccess(data) {
            this.form.preview = data.LookUrl;
            this.form.cover = data.BaseLookUrl;
        },

        // 文件上传之前
        onBeforeFileUpload(rawFile) {
            // console.log('rawFile: ', rawFile);
            const imgTypes = ['application/pdf', 'application/doc'];

            if (!imgTypes.includes(rawFile.type)) {
                // ElMessage.error('Avatar picture must be JPG format!')
                this.$message({
                    type: 'danger',
                    message: '请上传pdf,doc格式的文件'
                });

                return false;
            }
            return true;
        },

        // 自定义上传文件
        onFileUpload(params) {
            this.loadingIns = this.$loading({
                text: '文件上传中...',
                background: 'rgba(0, 0, 0, .2)'
            });

            api_uploadFile({ fileName: params.file.name }).then(res => {
                // 获取参数成功后，调用腾讯云接口
                const { UploadUrl, Host, Authorization, BaseLookUrl } = res.data;

                fetch(UploadUrl, {
                    method: 'PUT',
                    body: params.file,
                    headers: {
                        'Host': Host,
                        'Authorization': Authorization
                    }
                }).catch(res => {
                    return res.json();
                }).then(res => {
                    this.form.attachment = [{ name: params.file.name, url: BaseLookUrl }];
                    this.loadingIns.close();
                });
            }).catch(e => {
                this.loadingIns.close();
            });
        },

        // 取消
        handleCancel(formName) {
            this.$refs[formName].resetFields();
        },

        // 确定
        handleConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    this.confirmDisabled = true;

                    const { title, articleCatValue, needPoints, summary, content, cover, attachment } = this.form;
                    const payload = {
                        title, 
                        articleCatValue, 
                        needPoints: Number(needPoints),
                        summary, content, cover, attachment
                    };

                    if (this.articleId) { // 编辑
                        payload.id = this.articleId;
                        editArticleApi(payload).then(res => {
                            this.confirmLoading = false;
                            this.confirmDisabled = false;
                            this.$message({
                                type: 'success',
                                message: '操作成功！'
                            });

                            setTimeout(() => {
                                this.$router.push('/article/list');
                            }, 1000);
                        }).catch(() => {
                            this.confirmLoading = false;
                            this.confirmDisabled = false;
                        });
                    } else {
                        createArticleApi(payload).then(res => {
                            this.confirmLoading = false;
                            this.confirmDisabled = false;
                            this.$message({
                                type: 'success',
                                message: '操作成功！'
                            });

                            setTimeout(() => {
                                this.$router.push('/article/list');
                            }, 1000);
                        }).catch(() => {
                            this.confirmLoading = false;
                            this.confirmDisabled = false;
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-card__outer {
    .el-form-item {
        display: flex;
        flex-direction: column;
    }
    ::v-deep.el-form-item__label {
        text-align: left!important;
    }
}
.drag-upload {    
    ::v-deep.el-upload {
        display: block;
    }
    ::v-deep.el-upload-dragger {
        width: 100%;
        height: 200px;
    }
}
</style>
